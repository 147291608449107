/* This file is for your main application css. */

.meditations_side_scroll {
    overflow: auto;
    white-space: nowrap;
}

.meditation_preview {
    /*display: inline-block;*/
    width: 500px;
    text-align: center;
    border-radius: 25px;
    border: 1px solid #a2a2a2;
    padding: 10px;
    margin: 10px;
}

.meditation_preview_link {
    text-decoration: none;
    font-style: bold;
    color: #020202;
}

.meditation_preview_link:hover {
    text-decoration: none;
    color: #020202;
}

.meditation_preview_img {
    object-fit: cover;
    height: 50px;
    width: 50px;
}

.meditation_large_preview_img {
    object-fit: cover;
    height: 200px;
    width: 250px;
}

.meditation_preview_title {
    font-size: 16pt;
}

.meditation_list {
    list-style-type:none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
